<template>
  <PageTitle hide-back>Select Meter</PageTitle>
  <div class="content">
    <div class="card mb-3">
      <div v-if="loading" class="spinner-wrapper">
        <SpinnerLogo />
      </div>
      <div v-else>
        <div class="mb-2">
          <p v-if="utilStore.isOffline" class="alert alert-warning mb-1">
            You are offline. The following sites and meters may not be up-to-date.
          </p>
          <p class="mb-1 font-w600">1. Select a Site</p>

          <AssetSelector :assets="assetStore.assets" @select="onSelectAsset"></AssetSelector>
        </div>

        <div v-if="wizardStore.selectedAsset" class="mb-5">
          <p class="mb-1 font-w600">2. Select a Meter</p>

          <AccountSelector
            :accounts="accountStore.accountsByAssetId(wizardStore.selectedAsset._id)"
            @select="onSelectAccount"
          ></AccountSelector>
        </div>
      </div>

      <table v-if="wizardStore.selectedAccount && wizardStore.selectedAsset">
        <tbody>
          <tr>
            <th>Meter Name</th>
            <td>{{ wizardStore.selectedAccount.name }}</td>
          </tr>
          <tr>
            <th>MPAN</th>
            <td>{{ wizardStore.selectedAccount.meterPointNumber }}</td>
          </tr>
          <tr>
            <th>Serial No.</th>
            <td>{{ wizardStore.selectedAccount.meterSerialNumber }}</td>
          </tr>
          <tr v-if="wizardStore.selectedAccount.deviceId">
            <th>Device ID</th>
            <td>{{ wizardStore.selectedAccount.deviceId }}</td>
          </tr>
          <tr>
            <th>Type</th>
            <td class="text-capitalize">
              {{ wizardStore.selectedAccount.type }}
              <span v-if="wizardStore.selectedAccount.parentAccount">(Sub-meter)</span>
            </td>
          </tr>
          <tr v-if="wizardStore.selectedAccount.parentAccount">
            <th>Parent Account</th>
            <td>
              [{{ wizardStore.selectedAccount.parentAccount.meterPointNumber || '-' }}]
              {{ wizardStore.selectedAccount.parentAccount.name }}
            </td>
          </tr>
          <tr>
            <th>Location</th>
            <td>{{ wizardStore.selectedAccount.location }}</td>
          </tr>
          <tr>
            <th>Site</th>
            <td>
              {{ wizardStore.selectedAsset.siteName }}<br />
              {{ wizardStore.selectedAsset.addressString }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- <div class="btn btn-primary" @click="loadAssetsAndAccounts">Refresh</div> -->
  <FooterBox v-if="wizardStore.selectedAccount && wizardStore.selectedAsset" @next="onClickNext" />
</template>
<script lang="ts" setup>
import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';

import { useStore as useAccountStore } from '@/stores/account';
import { useStore as useAssetStore } from '@/stores/asset';
import { useStore as useUtilStore } from '@/stores/util';
import { useStore as useWizardStore } from '@/stores/wizard';

import AccountSelector from '@/components/AccountSelector.vue';
import AssetSelector from '@/components/AssetSelector.vue';
import PageTitle from '@/components/PageTitle.vue';
import SpinnerLogo from '@/components/SpinnerLogo.vue';
import FooterBox from '@/components/FooterBox.vue';

const accountStore = useAccountStore();
const assetStore = useAssetStore();
const wizardStore = useWizardStore();
const utilStore = useUtilStore();
const $router = useRouter();

const loading = ref(true);

onBeforeMount(() => {
  loadAssetsAndAccounts();
});

const loadAssetsAndAccounts = async () => {
  loading.value = true;

  await Promise.all([assetStore.listAssets(), accountStore.listAccounts()]);

  loading.value = false;
};

const onSelectAsset = (val: string) => {
  wizardStore.assetId = val;
};

const onSelectAccount = (val: string) => {
  wizardStore.accountId = val;
};

utilStore.$subscribe((mutation, state) => {
  if (!state.isOffline) {
    loadAssetsAndAccounts();
  }
});

const onClickNext = () => {
  $router.push({ name: 'reading' });
};
</script>
